<template>
  <div class="row justify-content-center" :style="`font-family:${font},sans-serif`">
    <div class="col-sm-12 col-md-12 card p-1">
      <div>
        <div class="title head">
          <p class="h2" style="color: #558cef">ค้นหาทรัพย์สิน</p>
        </div>
        <hr />
        <!-- search input -->
        <div class="custom-search row justify-content-between align-items-center">
          <!-- <div class="col-12 col-md-4 mb-1">
            <v-select v-model="workG" label="title" :options="workGs" />
          </div> -->
          <b-form-group class="col-12 col-md-4">
            <div class="d-flex align-items-center">
              <label class="mr-1 col-3">หมายเลขทรัพย์สิน</label>
              <b-form-input
                v-model="felter.e_number"
                placeholder="หมายเลขทรัพย์สิน"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
          <b-form-group class="col-12 col-md-4">
            <div class="d-flex align-items-center">
              <label class="mr-1 col-3">Serial Number</label>
              <b-form-input
                v-model="felter.serial_number"
                placeholder="Serial Number"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
          <b-form-group class="col-12 col-md-4">
            <div class="d-flex align-items-center">
              <label class="mr-1 col-3">ชื่อทรัพย์สิน</label>
              <b-form-input v-model="felter.e_name" placeholder="ชื่อทรัพย์สิน" type="text" class="d-inline-block" />
              <b-button @click="get_data_table">
                <label style="color: #ffff">ค้นหา</label>
              </b-button>
            </div>
          </b-form-group>
        </div>

        <!-- table -->
        <b-overlay :show="show_a" rounded="sm">
          <vue-good-table
            :columns="columns_a"
            :rows="rows_a"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm,
            }"
            :pagination-options="{
              enabled: true,
              perPage: pageLength_a,
            }"
            :line-numbers="true"
          >
            <template slot="table-row" slot-scope="props">
              <!-- Column: Name -->

              <!-- Column: Action -->
              <span v-if="props.column.field === 'action'">
                <span>
                  <b-button variant="outline-primary" class="btn-icon mr-1" v-b-modal="`modal-1${props.index}`">
                    <feather-icon icon="ToolIcon" size="16" class="mx-1" />
                  </b-button>
                </span>
                <b-modal
                  :id="`modal-1${props.index}`"
                  title="เพิ่มประวัติเเจ้งซ่อม"
                  ok-only
                  ok-title="ตกลง"
                  @ok="post_data(props.row)"
                  no-close-on-backdrop
                >
                  <b-card-text>
                    <!-- {{ props.row }} -->
                    <b-col md="12" xl="12" class="mb-1">
                      <!-- basic -->
                      <b-form-group label="หมายเลขทรัพย์สิน" label-for="basicInput">
                        <b-form-input v-model="props.row.e_number" placeholder="01-001-0001/64" disabled />
                      </b-form-group>
                    </b-col>

                    <b-col md="12" xl="12" class="mb-1">
                      <!-- basic -->
                      <b-form-group label="Serial Number" label-for="basicInput">
                        <b-form-input v-model="props.row.serial_number" placeholder="S/N : F69155111" disabled />
                      </b-form-group>
                    </b-col>

                    <b-col md="12" xl="12" class="mb-1">
                      <!-- basic -->
                      <b-form-group label="ชื่อทรัพย์สิน" label-for="basicInput">
                        <b-form-input v-model="props.row.e_name" placeholder="คอมพิวเตอร์" disabled />
                      </b-form-group>
                    </b-col>
                    <b-col md="12" xl="12" class="mb-1">
                      <!-- basic -->
                      <b-form-group label="รายละเอียด" label-for="basicInput">
                        <b-form-textarea
                          v-model="props.row.description"
                          placeholder="รายละเอียดประวัติเเจ้งซ่อม"
                          rows="3"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="12" xl="12" class="mb-1">
                      <!-- basic -->
                      <b-form-group label="ราคา" label-for="basicInput">
                        <b-form-input
                          v-model="props.row.price"
                          placeholder="ราคา"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                        />
                      </b-form-group>
                    </b-col>

                    <b-col md="12" xl="12" class="mb-1">
                      <!-- basic -->
                      <b-form-group label="วันที่เเจ้งซ่อม" label-for="basicInput">
                        <!-- <b-form-datepicker
                          id="example-datepicker"
                          v-model="props.row.inform_date"
                          class="mb-1"
                          locale="th-TH"
                        /> -->

                        <v-date-picker
                          id="example-datepicker3"
                          label="วันที่เเจ้งซ่อม:"
                          locale="th-TH"
                          v-model="date1"
                          @update:from-page="getHeaderInputDate"
                        >
                          <template v-slot="{ inputValue, inputEvents }">
                            <b-form-input :value="formatDate(inputValue, 'date1')" v-on="inputEvents" />
                          </template>
                          <template #header-title>
                            {{ headerInputDate }}
                          </template>
                        </v-date-picker>
                      </b-form-group>
                    </b-col>
                    <b-col md="12" xl="12" class="mb-1">
                      <!-- basic -->
                      <b-form-group label="วันที่รับคืน" label-for="basicInput">
                        <!-- <b-form-datepicker
                          id="example-datepicker2"
                          v-model="props.row.takeback_date"
                          class="mb-1"
                          locale="th-TH"
                        /> -->
                        <v-date-picker
                          id="example-datepicker3"
                          label="วันที่รับคืน:"
                          locale="th-TH"
                          v-model="date2"
                          @update:from-page="getHeaderInputDate"
                        >
                          <template v-slot="{ inputValue, inputEvents }">
                            <b-form-input :value="formatDate(inputValue, 'date2')" v-on="inputEvents" />
                          </template>
                          <template #header-title>
                            {{ headerInputDate }}
                          </template>
                        </v-date-picker>
                      </b-form-group>
                    </b-col>
                  </b-card-text>
                </b-modal>
              </span>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap"> Showing 1 to </span>
                  <b-form-select
                    v-model="pageLength_a"
                    :options="['5', '10', '15', '25', '50', '100']"
                    class="mx-1"
                    @input="(value) => props.perPageChanged({ currentPerPage: value }, handleChangePage(value))"
                  />
                  <span class="text-nowrap">of {{ total_a }} entries</span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="total_a"
                    :per-page="pageLength_a"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="handlePagechange($event)"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-overlay>
        <!-- basic modal -->
      </div>
    </div>

    <!-- ประวัติการแจ้งซ่อม -->
    <div class="col-sm-12 col-md-12 card p-1">
      <div>
        <div class="title head">
          <p class="h2" style="color: #558cef">ประวัติการแจ้งซ่อม</p>
        </div>
        <hr />
        <!-- search input -->
        <div class="custom-search row justify-content-end align-items-center">
          <!-- <div class="col-12 col-md-4 mb-1">
            <v-select v-model="workG" label="title" :options="workGs" />
          </div> -->
          <b-form-group class="col-12 col-md-6">
            <div class="d-flex align-items-center">
              <label class="mr-1">ค้นหา</label>
              <b-form-input v-model="searchTerm2" placeholder="ค้นหา" type="text" class="d-inline-block" />
              <div>
                <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                  <template v-slot:button-content>
                    <b-button variant="outline-primary"
                      ><feather-icon icon="ShareIcon" class="mr-50" />Export
                      <feather-icon icon="ChevronDownIcon" class="mr-50"
                    /></b-button>
                  </template>

                  <b-dropdown-item @click="exportExcel">
                    <feather-icon icon="FileIcon" class="mr-50" />
                    <span>Excel</span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </b-form-group>
        </div>

        <!-- table -->
        <b-overlay :show="show" rounded="sm">
          <vue-good-table
            :columns="columns_b"
            :rows="rows_b"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm2,
            }"
            :pagination-options="{
              enabled: true,
              perPage: pageLength_b,
            }"
            :line-numbers="true"
          >
            <template slot="table-row" slot-scope="props">
              <!-- Column: Name -->
              <span v-if="props.column.field === 'fullName'" class="text-nowrap">
                <b-avatar :src="props.row.avatar" class="mx-1" />
                <span class="text-nowrap">{{ props.row.fullName }}</span>
              </span>

              <span v-else-if="props.column.field === 'จำนวน'">
                <div class="text-right">{{ props.row.จำนวน }}</div>
              </span>
              <span v-else-if="props.column.field === 'ราคาต่อหน่วย'">
                <div class="text-right">{{ props.row.ราคาต่อหน่วย }}</div>
              </span>

              <!-- Column: Action -->
              <span v-else-if="props.column.field === 'action'">
                <span>
                  <b-button
                    variant="outline-primary"
                    class="btn-icon mr-1"
                    @click="$router.push({ name: 'tableinkuruplun' })"
                  >
                    <feather-icon icon="EyeIcon" size="16" class="mx-1" />
                  </b-button>
                </span>
              </span>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap"> Showing 1 to </span>
                  <b-form-select
                    v-model="pageLength_b"
                    :options="['5', '10', '15', '25', '50', '100']"
                    class="mx-1"
                    @input="(value) => props.perPageChanged({ currentPerPage: value })"
                  />
                  <span class="text-nowrap">of {{ total_b }} entries</span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="total_b"
                    :per-page="pageLength_b"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value) => props.pageChanged({ currentPage: value })"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BButton,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import vSelect from "vue-select";
import axios from "axios";
import API from "@/views/connectDB/condb.js";
import { saveExcel } from "@progress/kendo-vue-excel-export";
import { DatePicker } from "v-calendar";
export default {
  components: {
    vSelect,
    BButton,
    BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    DatePicker,
  },

  data() {
    return {
      val_inform_date: "",
      val_takeback_date: "",
      headerInputDate: "",
      date1: new Date(),
      date2: new Date(),
      show: false,
      show_a: false,
      felter: {
        serial_number: "",
        e_name: "",
        e_number: "",
      },

      pageLength_a: 5,
      currentPage_a: 1,
      total_a: "",
      columns_a: [
        {
          label: "หมายเลขทรัพย์สิน",
          field: "e_number",
          width: "160px",
        },
        {
          label: "Serial Number",
          field: "serial_number",
          width: "160px",
        },
        {
          label: "ชื่อทรัพย์สิน",
          field: "e_name",
        },
        {
          label: "สถานที่เก็บ",
          field: "place",
        },
        {
          label: "วันที่รับ",
          field: "admitdate_th",
        },
        {
          label: "ราคา + vat 7%",
          field: "unitprice_vat",
          type: "number",
          formatFn: this.formatFn,
        },

        {
          field: "action",
          width: "95px",
        },
      ],
      rows_a: [],
      searchTerm: "",

      pageLength_b: 5,
      currentPage_b: 1,
      total_b: "",
      columns_b: [
        {
          label: "หมายเลขทรัพย์สิน",
          field: "e_number",
          width: "160px",
        },
        {
          label: "Serial Number",
          field: "serial_number",
          width: "160px",
        },
        {
          label: "ชื่อทรัพย์สิน",
          field: "e_name",
        },
        {
          label: "รายละเอียด",
          field: "description",
        },

        {
          label: "วันที่เเจ้ง",
          field: "inform_date",
        },
        {
          label: "วันที่รับคืน",
          field: "takeback_date",
        },
        {
          label: "ผู้รับผิดฃชอบ",
          field: "responsename",
        },

        {
          label: "ราคา",
          field: "price",
          type: "number",
          formatFn: this.formatFn,
        },

        // {
        //   field: "action",
        //   width: '95px'
        // },
      ],
      rows_b: [],
      searchTerm2: "",
      month: [
        "",
        "มกราคม",
        "กุมภาพันธ์ ",
        "มีนาคม ",
        "เมษายน ",
        "พฤษภาคม ",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
      ],
    };
  },
  created() {
    const font = localStorage.getItem("font");
    console.log(font);
    this.font = font;
    this.get_table();
  },
  methods: {
    formatFn: function (value) {
      return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    },
    exportExcel() {
      saveExcel({
        data: this.rows_b,
        fileName: "ประวัติการแจ้งซ่อม",
        columns: [
          {
            title: "ประวัติการแจ้งซ่อม",
            headerCellOptions: { textAlign: "center" },
            children: [
              {
                title: "หมายเลขทรัพย์สิน",
                field: "e_number",
                width: "160px",
              },
              {
                title: "Serial Number",
                field: "serial_number",
                width: "160px",
              },
              {
                title: "ชื่อทรัพย์สิน",
                field: "e_name",
              },
              {
                title: "รายละเอียด",
                field: "description",
              },

              {
                title: "วันที่เเจ้ง",
                field: "inform_date",
              },
              {
                title: "วันที่รับคืน",
                field: "takeback_date",
              },
              {
                title: "ผู้รับผิดชอบ",
                field: "responsename",
              },
              {
                title: "ราคา",
                field: "price_ex",
                type: "number",
              },
            ],
          },
        ],
      });
    },
    async get_table() {
      this.show = true;
      const { access_token } = await this.access_token();
      const url = `${API}historyRepair?_sort=1`;
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, header);
      console.log(res.data.message.data);

      const dd = res.data.message.data.sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.inform_date) - new Date(a.inform_date);
      });
      console.log(dd);
      this.rows_b = dd.map((element) => {
        return {
          ...element,
          inform_date: `${parseInt(element.inform_date.split("-")[2])} ${
            ///////////////เปลี่ยน ชื่อ KEY ด้วย//////////////////////
            this.month[parseInt(element.inform_date.split("-")[1])]
          } ${Number(element.inform_date.split("-")[0]) + 543}`,
          takeback_date: `${parseInt(element.takeback_date.split("-")[2])} ${
            this.month[parseInt(element.inform_date.split("-")[1])]
          } ${Number(element.takeback_date.split("-")[0]) + 543}`,
          price_ex: Number(element.price),
        };
      });
      this.total_b = res.data.message.total;
      this.show = false;
    },
    async post_data(params) {
      console.log(params);

      const { access_token } = await this.access_token();
      const url = `${API}historyRepair`;
      const data = {
        e_number: params.e_number,
        description: params.description,
        price: params.price,
        inform_date: params.inform_date || this.val_inform_date,
        takeback_date: params.takeback_date || this.val_takeback_date,
      };
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.post(url, data, head);
      // console.log(res.data.status);
      if (res.data.status === 200) {
        Swal.fire({
          position: "top-center",
          icon: "success",
          title: "บันทึกสำเร็จ",
          showConfirmButton: false,
          timer: 1500,
        });
        this.get_table();
      } else {
        Swal.fire({
          position: "top-center",
          icon: "error",
          title: res.data.message,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    changeCell(vaule, column, row) {
      this.rows[row][column] = vaule;
    },
    async get_data_table() {
      this.show_a = true;
      const { access_token } = await this.access_token();
      const url = `${API}historyListOfRepairs?e_number=${this.felter.e_number}&serial_number=${this.felter.serial_number}&e_name=${this.felter.e_name}&_page=${this.currentPage_a}&_limit=${this.pageLength_a}`;
      console.log(url);
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      console.log(res.data.message.data);
      const gen = res.data.message.data.map((aa) => {
        return {
          description: "",
          price: "",
          inform_date: "",
          takeback_date: "",
          admitdate_th: `${parseInt(aa.admitdate.split("-")[2])} ${this.month[parseInt(aa.admitdate.split("-")[1])]} ${
            Number(aa.admitdate.split("-")[0]) + 543
          }`,
          ...aa,
        };
      });
      this.rows_a = gen;
      this.total_a = res.data.message.total;
      this.show_a = false;
    },
    handlePagechange(event) {
      this.currentPage_a = event;
      this.get_data_table();
    },
    handleChangePage(event) {
      this.pageLength_a = event;
      this.get_data_table();
    },
    async access_token() {
      return (
        await axios.post(`${API}permit`, "", {
          headers: {
            Authorization: localStorage.getItem("storedData") && JSON.parse(localStorage.getItem("storedData")),
          },
        })
      ).data.message;
    },
    getHeaderInputDate(events) {
      switch (events.month) {
        case 1:
          this.headerInputDate = "มกราคม " + (events.year + 543);
          break;
        case 2:
          this.headerInputDate = "กุมภาพันธ์ " + (events.year + 543);
          break;
        case 3:
          this.headerInputDate = "มีนาคม " + (events.year + 543);
          break;
        case 4:
          this.headerInputDate = "เมษายน " + (events.year + 543);
          break;
        case 5:
          this.headerInputDate = "พฤษภาคม " + (events.year + 543);
          break;
        case 6:
          this.headerInputDate = "มิถุนายน " + (events.year + 543);
          break;
        case 7:
          this.headerInputDate = "กรกฎาคม " + (events.year + 543);
          break;
        case 8:
          this.headerInputDate = "สิงหาคม " + (events.year + 543);
          break;
        case 9:
          this.headerInputDate = "กันยายน " + (events.year + 543);
          break;
        case 10:
          this.headerInputDate = "ตุลาคม " + (events.year + 543);
          break;
        case 11:
          this.headerInputDate = "พฤศจิกายน " + (events.year + 543);
          break;
        case 12:
          this.headerInputDate = "ธันวาคม " + (events.year + 543);
          break;
        default:
          this.headerInputDate = "ข้อมูลผิดพลาด";
          break;
      }
      console.log(this.headerInputDate);
    },
    formatDate(date, val) {
      let formatDate = "";
      let dete_val = "";
      if (date != null) {
        const _y = date.split("/");
        const _date = parseInt(_y[2]) + 543;
        formatDate = `${_y[0]}/${_y[1]}/${_date}`;
        dete_val = _y[2] + "-" + _y[1] + "-" + _y[0];
        switch (val) {
          case "date1":
            this.val_inform_date = dete_val;
            break;
          case "date2":
            this.val_takeback_date = dete_val;
            break;
        }
      } else {
        formatDate = "-";
      }
      if (formatDate.toString() == "/undefined/NaN") {
        formatDate = "";
      }

      return formatDate;
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
